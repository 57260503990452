import {createBrowserRouter} from "react-router-dom";
import Home from '../pages/home';
import Privacy from "../pages/privacy";
import Terms from "../pages/terms";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/privacy",
      element: <Privacy />
    },
    {
      path: "/terms",
      element: <Terms />
    }
  ]);

export default router;