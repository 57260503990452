import * as React from "react"
const CursorWhite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={143}
    height={111}
    fill="none"
    {...props}
  >
    <path stroke="#C0D2EB" strokeWidth={5} d="M2.5 2.5h112v67H2.5z" />
    <text textAnchor="middle" fill="#fff" x="55" y="43">App Bar!</text>
    <path
      fill="#fff"
      d="m130.362 71.92-31.36-10.388-.003-.001c-2.208-.732-3.313-1.098-4.07-.838a2.375 2.375 0 0 0-1.44 1.38c-.292.746.026 1.866.663 4.106l9.039 31.775c.81 2.848 1.216 4.272 1.957 4.758.643.42 1.448.505 2.164.226.825-.322 1.516-1.632 2.896-4.252l6.43-12.201c.209-.397.317-.6.457-.776.128-.159.277-.3.441-.42.185-.137.397-.238.822-.439l12.435-5.89c2.677-1.267 4.016-1.902 4.373-2.713a2.373 2.373 0 0 0-.134-2.17c-.453-.761-1.859-1.227-4.67-2.158Z"
    />
  </svg>
)
export default CursorWhite
