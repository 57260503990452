import React from "react";
import useStyles from "./style"
import logo from "../../assets/img/logo.png"
import appstore from "../../assets/img/appstore.png"
import sectionOne from "../../assets/img/sectionOne.png"
import colorblindType from "../../assets/img/colorblindType.png"
import screenType from "../../assets/img/screenType.png"
import enhancer from "../../assets/img/enhancer.png"
import screenEnhancer from "../../assets/img/screenEnhancer.png"
import simulator from "../../assets/img/simulator.png"
import screenSimulator from "../../assets/img/screenSimulator.png"
import detector from "../../assets/img/detector.png"
import screenDetector from "../../assets/img/screenDetector.png"
import orcaOnMac from "../../assets/img/orcaOnMac.png"
import CursorWhite from "../../assets/svg/cursorWhite";
import barcodeAppstore from "../../assets/img/barcodeAppstore.png";

const Home = () => {
  const styles = useStyles();
  return (
    <div className={styles.app}>
      <header className={styles.headerApp}>
        <img src={logo} alt="Orca: Colorblind Assistant" className="appLogo" />
        <div className={styles.navMenu}>
          <a className="navItem" href="#about">About us</a>
          <a className="navItem" href="#contact">Contact us</a>
        </div>
      </header>
      <section className={styles.sectionTitle}>
        <h1 className="title">Experience Colors You’ve Never Seen Before</h1>
        <span className="body">Enhance colors for you and see the true beauty of the world!</span>
        <a href="https://apps.apple.com/id/app/orca-colorblind-assistant/id6476919624">
          <img src={appstore} alt="Appstore Orca" className="testflight" />
        </a>
      </section>
      <img src={sectionOne} alt="Features Orca" className="image" />
      <section id="about" className={styles.sectionAboutUs}>
        <h2 className="title">About Us</h2>
        <p className="content">At Orca, our mission is to make the world of color more accessible to individuals with color vision deficiencies. We believe that everyone deserves to experience the richness of color in daily life, whether for work, social activities, or personal enjoyment. Our app, Orca Colorblind Assistant, is designed to bridge gaps in color perception by offering practical, user-friendly tools that help users distinguish and identify colors more effectively. Driven by a passion for inclusivity and innovation, our team works continuously to improve the app and ensure it meets the diverse needs of the colorblind community.</p>
      </section>
      <section className={styles.sectionFeatures}>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={colorblindType} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Colorblind <h5 className="square">Type</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Personalized Just For You. Choose your type of colorblindness and sensitivity.</span>
          </div>
          <img src={screenType} alt="Colorblind Type" className="imgFeature" />
        </section>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={enhancer} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Color <h5 className="square">Enhancer</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Experience colors that you’ve never seen before. The Enhancer mode adjusts the colors of objects to make them appear more vibrant based on colorblind type.</span>
          </div>
          <img src={screenEnhancer} alt="Colorblind Type" className="imgFeature" />
        </section>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={simulator} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Color <h5 className="square">Simulator</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Simulate how colorblind people sees the world.</span>
          </div>
          <img src={screenSimulator} alt="Colorblind Type" className="imgFeature" />
        </section>
        <section className="containerFeature">
          <div className="column">
            <div className="row">
              <img src={detector} alt="colorblind type" className="image" />
              <div className="title">
                <h5 className="titleFeature">Color <h5 className="square">Detector</h5></h5>
              </div>
            </div>
            <span className="bodyFeature">Identify colors around you by simply pointing device's camera.</span>
          </div>
          <img src={screenDetector} alt="Colorblind Type" className="imgFeature" />
        </section>
      </section>
      <section className={styles.sectionContact} id="contact">
        <div className="sectionTitle">
          <h2 className="title">Contact Us</h2>
          <p className="content">Let us know how we can help.</p>
        </div>
        <div className="contactList">
          <div className="contactItem">
            <svg width="62" height="51" viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.5 2H7.83333C4.61167 2 2 4.61167 2 7.83333V42.8333C2 46.055 4.61167 48.6667 7.83333 48.6667H54.5C57.7217 48.6667 60.3333 46.055 60.3333 42.8333V7.83333C60.3333 4.61167 57.7217 2 54.5 2Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M60.3333 10.75L34.1708 27.375C33.2704 27.9392 32.2293 28.2384 31.1667 28.2384C30.1041 28.2384 29.063 27.9392 28.1625 27.375L2 10.75" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div className="contactContent">
              <div className="titleContact">
                <h6 className="title">Email</h6>
                <p className="note">Contact to our team.</p>
              </div>
              <a className="content" href="mailto:orcabyzen@gmail.com">orcabyzen@gmail.com</a>
            </div>
          </div>
          <div className="contactItem">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.1667 60.3333C47.275 60.3333 60.3333 47.275 60.3333 31.1667C60.3333 15.0584 47.275 2 31.1667 2C15.0584 2 2 15.0584 2 31.1667C2 47.275 15.0584 60.3333 31.1667 60.3333Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M2 31.1667H60.3333M31.1667 2C23.6773 9.86378 19.5 20.3072 19.5 31.1667C19.5 42.0262 23.6773 52.4695 31.1667 60.3333C38.656 52.4695 42.8333 42.0262 42.8333 31.1667C42.8333 20.3072 38.656 9.86378 31.1667 2Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div className="contactContent">
              <div className="titleContact">
                <h6 className="title">Website</h6>
                <p className="note">Contact to our team.</p>
              </div>
              <a className="content" href="https://www.orcabyzen.com">www.orcabyzen.com</a>
            </div>
          </div>
          <div className="contactItem">
            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M45.75 2H16.5833C8.52918 2 2 8.52918 2 16.5833V45.75C2 53.8041 8.52918 60.3333 16.5833 60.3333H45.75C53.8041 60.3333 60.3333 53.8041 60.3333 45.75V16.5833C60.3333 8.52918 53.8041 2 45.75 2Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M47.2081 15.125H47.2373M42.8331 29.3292C43.1931 31.7565 42.7785 34.2356 41.6483 36.4138C40.5181 38.592 38.7298 40.3583 36.5379 41.4615C34.3459 42.5648 31.8619 42.9488 29.4392 42.559C27.0164 42.1691 24.7783 41.0252 23.0431 39.2901C21.3079 37.5549 20.164 35.3167 19.7742 32.894C19.3843 30.4712 19.7683 27.9872 20.8716 25.7953C21.9748 23.6033 23.7412 21.8151 25.9193 20.6849C28.0975 19.5547 30.5766 19.14 33.004 19.5C35.48 19.8672 37.7723 21.0209 39.5422 22.7909C41.3122 24.5609 42.466 26.8531 42.8331 29.3292Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div className="contactContent">
              <div className="titleContact">
                <h6 className="title">Instagram</h6>
                <p className="note">Contact to our team.</p>
              </div>
              <a className="content" href="https://instagram.com/orcabyzen">@orcabyzen</a>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.sectionMac}>
        <div className="background"></div>
        <div className="content">
          <h6 className="title">Soon Available in MacOS!</h6>
          <span className="body">
            <span className="text">Unleash the power of simplicity with Orca accessible right from the</span>
            <CursorWhite className="svg" />
          </span>
          <img src={orcaOnMac} alt="Orca on MacOS" className="imgMac" />
        </div>
      </section>
      <section className={styles.sectionTry}>
        <div className="column">
          <h3 className="title">TRY ORCA NOW!</h3>
          <span className="body">Enhance colors for you and see the true beauty of the world!</span>
          <a href="https://apps.apple.com/id/app/orca-colorblind-assistant/id6476919624">
            <img src={appstore} alt="Appstore Orca" className="testflight" />
          </a>
        </div>
        <img src={barcodeAppstore} alt="Barcode Tesflight" className="barcode" />
      </section>
      <footer className={styles.footerApp}>
        <div>
          <img src={logo} alt="Orca: Colorblind Assistant" className="appLogo" />
        </div>
        <p className="copy">@ 2023 All rights reserved.</p>
        <div className="containerInfo">
          <a className="itemInfo" href="https://www.instagram.com/orcabyzen">
            Disclaimer
          </a>
          <a className="itemInfo" href="/terms">
            Terms & Conditions
          </a>
          <a className="itemInfo" href="/privacy">
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  )
};

export default Home;
